.img-overlay {
  z-index: 9999;
}

.img-64 {
  max-width: 64px;
  max-height: 64px;
}

.img-128 {
  max-width: 128px;
  max-height: 128px;
}

.img-100 {
  max-width: 100px;
  max-height: 100px;
}

.img-responsive {
  width: 100%;
  max-width: 100px;
  height: auto;
}

.sp-avatar {
  background-color: lightgrey;
  border: 2px solid $color-blue;

  &.border-none {
    border: none !important;
  }

  &.avatar-composite {
    width: 100%;
    height: 170px;

    @include respond-to(small-phone) {
      height: 145px;
    }
    @include respond-to(phone) {
      height: 145px;
    }
    @include respond-to(phablet) {
      height: 145px;
    }
    @include respond-to(tablet) {
      height: 170px;
    }
    @include respond-to(desktop) {
      height: 170px;
    }
    @include respond-to(wide-desktop) {
      height: 170px;
    }
    @include respond-to(x-wide-desktop) {
      height: 170px;
    }
  }

  img {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: 30px;
    margin: auto;
    max-height: 150px;
    max-width: 150px;

    @include respond-to(x-small-phone) {
      bottom: 15px;
      max-height: 150px;
      max-width: 150px;
    }
    @include respond-to(small-phone) {
      bottom: 5px;
      max-height: 125px;
      max-width: 125px;
    }
    @include respond-to(phone) {
      bottom: 10px;
      max-height: 125px;
      max-width: 125px;
    }
    @include respond-to(phablet) {
      bottom: 10px;
      max-height: 125px;
      max-width: 125px;
    }
    @include respond-to(tablet) {
      bottom: 30px;
      max-height: 150px;
      max-width: 150px;
    }
    @include respond-to(desktop) {
      bottom: 30px;
      max-height: 150px;
      max-width: 150px;
    }
    @include respond-to(wide-desktop) {
      bottom: 30px;
      max-height: 150px;
      max-width: 150px;
    }
    @include respond-to(x-wide-desktop) {
      bottom: 30px;
      max-height: 150px;
      max-width: 150px;
    }
  }
}

.sp-assignment-avatar-group-icon {
  background-color: lightgrey;
  border: 2px solid $color-blue;
  max-height: 34px;
  max-width: 34px;
  padding: 0;
  margin-right: .25em;
  margin-bottom: .25em;

  img {
    left: 0;
    top: 0;
    max-height: 30px;
    max-width: 30px;
  }

}

.sp-assignment-avatar-group-overflow {
  background-color: white;
  border: 2px solid $color-blue;
  height: 34px;
  width: 34px;
  padding-top: .1em;
  margin-right: .25em;
  margin-bottom: .25em;
}
